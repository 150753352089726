import LocalizedStrings from 'react-localization';

export let localizedStrings = new LocalizedStrings({
    en: {
        // App head
        appTitle: 'Face Sherlock: search similar people by photo',
        appMetaDescription: 'Search people on your device photos by face',

        // Common
        unknownError: 'Unknown error',
        signIn: 'Sign In',
        signOut: 'Sign Out',
        country: 'Country',
        city: 'City',
        noOptions: 'No options',
        males: 'Males',
        females: 'Females',
        age: 'Age',
        from: 'From',
        to: 'To',
        of: 'Of',
        ageShort: 'y/o',
        cantDetectFace: 'Please select photo with clearly visible face',
        face: 'Face',
        noMatchesFound: 'No matches found',
        photoToRecognize: 'Photo to recognize',
        missingCityError: 'Please, select city',
        yes: 'yes',
        no: 'no',
        stopSearchConfirmation: 'Are you sure you want to finish search ? All search results will be removed',
        startSearch: 'Start search',
        supportUkraine: 'Support Ukraine',
        followUs: "Follow us",
        open: 'Open',
        copiedToClipboard: 'Copied to clipboard',
        close: 'Close',
        searchFinished: 'Search finished',
        selferAdMessage: 'Take a photo with {0} app',
        ihancerAdMessage: 'Enhance photo quality with {0} app to have better search results',
        privacyPolicy: 'Privacy Policy',

        // Faces search component
        facesSearchComponentSubtitle: 'Search people on your device photos by face',
        facesSearchComponentLoadingModels: 'Loading models',
        facesSearchComponentFaceExamples: 'Face examples',
        facesSearchComponentFaceExample: 'Face example',
        facesSearchComponentFacesToSearch: 'Faces to search',
        facesSearchComponentFaceToSearch: 'Face to search',
        facesSearchComponentTabVK: 'VKontakte',
        facesSearchComponentTabPhoto: 'Photo',
        facesSearchComponentHowItWorks: 'How it works',
        facesSearchComponentDescription: 'Do you need to find all photos on device with specific person? Try this app, it performs search of device photos by face. Can be used to find person by photo or to search for lookalikes. You can see search process in real time with the ability to stop it. Found photo can be deleted if needed or shared to other apps like social networks, messengers, etc. \n\nPhotos with faces you want to search can be selected from the gallery or taken from the camera. The app then automatically detects faces, if more than one is found, you can select a specific one to search. To recognize faces machine learning technology is used, so if photo contains at least one similar or same face it will be found.',

        // VK
        vkManagerAccessTokenNotFound: 'Access token not found',
        vkSearchComponentSearchParams: 'Search options',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'Search only very similar faces',
        vkSearchUsersNotFound: 'Users not found',
        joinVKGroup: 'Join our VK group to get more free apps',

        // Device search
        deviceSearchSelectFolder: 'Please select a folder with images to search for people',
        deviceSearchItemCopyName: 'Copy name',
        deviceSearchItemCopyLocation: 'Copy location',
    },

    ru: {
        // App head
        appTitle: 'Face Sherlock: поиск похожих людей по фото',
        appMetaDescription: 'Поиск людей на фотографиях вашего устройства по лицу',

        // Common
        unknownError: 'Неизвестная ошибка',
        signIn: 'Войти',
        signOut: 'Выйти',
        country: 'Страна',
        city: 'Город',
        noOptions: 'Нет вариантов',
        males: 'Мужчины',
        females: 'Женщины',
        age: 'Возраст',
        from: 'От',
        to: 'До',
        ageShort: 'л.',
        cantDetectFace: 'Пожалуйста, выберите фото с хорошо видимым лицом',
        face: 'Лицо',
        noMatchesFound: 'Совпадений не найдено',
        photoToRecognize: 'Фото для распознавания',
        missingCityError: 'Пожалуйста, выберите город',
        yes: 'Да',
        no: 'Нет',
        stopSearchConfirmation: 'Вы уверены, что хотите закончить поиск? Все результаты поиска будут удалены',
        startSearch: 'Начать поиск',
        supportUkraine: 'Поддержите Украину',
        followUs: "Подписывайтесь на нас",
        open: 'Открыть',
        copiedToClipboard: 'Скопировано в буфер обмена',
        close: 'Закрыть',
        searchFinished: 'Поиск завершен',
        selferAdMessage: 'Сделать фото с приложением {0}',
        ihancerAdMessage: 'Повысьте качество фотографий с помощью приложения {0}, чтобы получить лучшие результаты поиска',
        privacyPolicy: 'Политика конфиденциальности',

        // Faces search component
        facesSearchComponentSubtitle: 'Поиск людей на фотографиях вашего устройства по лицу',
        facesSearchComponentLoadingModels: 'Загрузка моделей',
        facesSearchComponentFaceExamples: 'Примеры лиц',
        facesSearchComponentFaceExample: 'Пример лица',
        facesSearchComponentFacesToSearch: 'Лица для поиска',
        facesSearchComponentFaceToSearch: 'Лицо для поиска',
        facesSearchComponentTabVK: 'ВКонтакте',
        facesSearchComponentTabPhoto: 'Фото',
        facesSearchComponentHowItWorks: 'Как это работает',
        facesSearchComponentDescription: 'Вам нужно найти все фотографии на устройстве с конкретным человеком? Попробуйте это приложение, оно выполняет поиск фотографий устройства по лицу. Может использоваться для поиска человека по фотографии или для поиска двойников. Вы можете наблюдать за процессом поиска в режиме реального времени с возможностью его остановки. Найденную фотографию можно удалить при необходимости или отправить в другие приложения, такие как социальные сети, мессенджеры и т. д. \n\nФотографии с лицами, которые вы хотите найти, могут быть выбраны из галереи или сфотографированы с камеры. Затем приложение автоматически обнаруживает лица, и если найдено более одного лица, вы можете выбрать конкретное для поиска. Для распознавания лиц используется технология машинного обучения, поэтому если на фото есть хотя бы одно похожее или одно и то же лицо, оно будет найдено.',

        // VK
        vkManagerAccessTokenNotFound: 'Токен доступа не найден',
        vkSearchComponentSearchParams: 'Параметры поиска',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'Искать только очень похожие лица',
        vkSearchUsersNotFound: 'Пользователей не найдено',
        joinVKGroup: 'Присоединяйтесь к нашей группе ВК, чтобы получить больше бесплатных приложений',

        // Device search
        deviceSearchSelectFolder: 'Выберите папку с изображениями для поиска людей',
        deviceSearchItemCopyName: 'Скопировать название',
        deviceSearchItemCopyLocation: 'Скопировать местоположение',
    },

    uk: {
        // App head
        appTitle: 'Face Sherlock: пошук схожих людей по фото',
        appMetaDescription: 'Пошук фотографій вашого пристрою за обличчям',

        // Common
        unknownError: 'Невідома помилка',
        signIn: 'Увійти',
        signOut: 'Вийти',
        country: 'Країна',
        city: 'Місто',
        noOptions: 'Немає варіантів',
        males: 'Чоловіки',
        females: 'Жінки',
        age: 'Вік',
        from: 'Від',
        to: 'До',
        ageShort: 'р.',
        cantDetectFace: 'Будь ласка, виберіть фотографію з добре видимим обличчям',
        face: 'Обличчя',
        noMatchesFound: 'Збігів не знайдено',
        photoToRecognize: 'Фото для розпізнавання',
        missingCityError: 'Будь ласка, виберіть місто',
        yes: 'Так',
        no: 'Ні',
        stopSearchConfirmation: 'Ви впевнені, що хочете закінчити пошук? Всі результати пошуку будуть видалені',
        startSearch: 'Почати пошук',
        supportUkraine: 'Підтримайте Україну',
        followUs: "Підписуйтесь на нас",
        open: 'Відкрити',
        copiedToClipboard: 'Скопійовано в буфер обміну',
        close: 'Закрити',
        searchFinished: 'Пошук завершено',
        selferAdMessage: 'Сфотографувати з додатком {0}',
        ihancerAdMessage: 'Покращуйте якість фотографій за допомогою програми {0}, щоб отримувати кращі результати пошуку',
        privacyPolicy: 'Політика конфіденційності',

        // Faces search component
        facesSearchComponentSubtitle: 'Пошук фотографій вашого пристрою за обличчям',
        facesSearchComponentLoadingModels: 'Завантаження моделей',
        facesSearchComponentFaceExamples: 'Приклади облич',
        facesSearchComponentFaceExample: 'Приклад обличчя',
        facesSearchComponentFacesToSearch: 'Обличчя для пошуку',
        facesSearchComponentFaceToSearch: 'Обличчя для пошуку',
        facesSearchComponentTabVK: 'ВКонтакті',
        facesSearchComponentTabPhoto: 'Фото',
        facesSearchComponentHowItWorks: 'Як це працює',
        facesSearchComponentDescription: 'Вам потрібно знайти всі фотографії на пристрої з конкретною людиною? Спробуйте цю програму, вона виконує пошук фотографій пристрою за обличчям. Можна використовувати для пошуку людини за фотографією або для пошуку двiйників. Ви можете бачити процес пошуку в режимі реального часу з можливістю його зупинити. Знайдене фото можна видалити, якщо потрібно, або поділитися з іншими додатками, такими як соціальні мережі, месенджери тощо. \n\nФотографії з обличчями, які потрібно знайти, можна вибрати з галереї або cфотографувати камерою. Потім програма автоматично виявляє обличчя, якщо знайдено більше одного, ви можете вибрати конкретне для пошуку. Для розпізнавання облич використовується технологія машинного навчання, тож якщо на фото є хоча б одне схоже або однакове обличчя, воно буде знайдено.',

        // VK
        vkManagerAccessTokenNotFound: 'Токен доступу не найдено',
        vkSearchComponentSearchParams: 'Параметри пошуку',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'Шукати лише дуже схожі обличчя',
        vkSearchUsersNotFound: 'Користувачів не знайдено',
        joinVKGroup: 'Приєднуйтесь до нашої групи ВК, щоб отримати більше безкоштовних додатків',

        // Device search
        deviceSearchSelectFolder: 'Виберіть папку із зображеннями для пошуку людей',
        deviceSearchItemCopyName: 'Копіювати назву',
        deviceSearchItemCopyLocation: 'Копіювати місцезнаходження',
    },

    es: {
        // App head
        appTitle: 'Face Sherlock: buscar gente similar por foto',
        appMetaDescription: 'Busca las fotos de tu dispositivo por rostro',

        // Common
        unknownError: 'Error desconocido',
        signIn: 'Iniciar sesión',
        signOut: 'Desconectar',
        country: 'País',
        city: 'Ciudad',
        noOptions: 'Sin opciones',
        males: 'Machos',
        females: 'Hembras',
        age: 'La edad',
        from: 'De',
        to: 'Para',
        of: 'De',
        ageShort: 'años',
        cantDetectFace: 'Seleccione una foto con la cara claramente visible',
        face: 'Cara',
        noMatchesFound: 'No se encontraron coincidencias',
        photoToRecognize: 'Foto para reconocer',
        missingCityError: 'Por favor, seleccione la ciudad',
        yes: 'sí',
        no: 'no',
        stopSearchConfirmation: '¿Estás seguro de que quieres terminar la búsqueda? Se eliminarán todos los resultados de la búsqueda.',
        startSearch: 'Iniciar busqueda',
        supportUkraine: 'Apoya a Ucrania',
        followUs: "Síganos",
        open: 'Abierto',
        copiedToClipboard: 'Copiado al portapapeles',
        close: 'Cerca',
        searchFinished: 'Búsqueda finalizada',
        selferAdMessage: 'Tome una foto con la aplicación {0}',
        ihancerAdMessage: 'Mejore la calidad de las fotos con la aplicación {0} para obtener mejores resultados de búsqueda',
        privacyPolicy: 'Política de privacidad',

        // Faces search component
        facesSearchComponentSubtitle: 'Busca las fotos de tu dispositivo por rostro',
        facesSearchComponentLoadingModels: 'Modelos de carga',
        facesSearchComponentFaceExamples: 'Ejemplos de cara',
        facesSearchComponentFaceExample: 'Ejemplo de cara',
        facesSearchComponentFacesToSearch: 'Caras para buscar',
        facesSearchComponentFaceToSearch: 'Cara a buscar',
        facesSearchComponentTabVK: 'VKontakte',
        facesSearchComponentTabPhoto: 'Foto',
        facesSearchComponentHowItWorks: 'Cómo funciona',
        facesSearchComponentDescription: '¿Necesita encontrar todas las fotos en el dispositivo con una persona específica? Pruebe esta aplicación, realiza una búsqueda de fotos del dispositivo por cara. Se puede usar para buscar personas por foto o para buscar parecidos. Puede ver el proceso de búsqueda en tiempo real con la capacidad de detenerlo. La foto encontrada se puede eliminar si es necesario o compartirse con otras aplicaciones como redes sociales, mensajeros, etc. \n\nLas fotos con caras que desea buscar se pueden seleccionar de la galería o tomar de la cámara. Luego, la aplicación detecta rostros automáticamente, si se encuentra más de uno, puede seleccionar uno específico para buscar. Para reconocer caras se utiliza tecnología de aprendizaje automático, por lo que si la foto contiene al menos una cara similar o igual, se encontrará.',

        // VK
        vkManagerAccessTokenNotFound: 'Acceso token no encontrado',
        vkSearchComponentSearchParams: 'Opciones de búsqueda',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'Buscar solo caras muy similares',
        vkSearchUsersNotFound: 'Usuarios no encontrados',
        joinVKGroup: 'Únete a nuestro grupo VK para obtener más aplicaciones gratuitas.',

        // Device search
        deviceSearchSelectFolder: 'Seleccione una carpeta con imágenes para buscar personas',
        deviceSearchItemCopyName: 'Copiar nombre',
        deviceSearchItemCopyLocation: 'Copiar ubicación',
    },

    pt: {
        // App head
        appTitle: 'Face Sherlock: pesquisar povos semelhantes por foto',
        appMetaDescription: 'Pesquise as fotos do seu dispositivo por rosto',

        // Common
        unknownError: 'Erro desconhecido',
        signIn: 'Entrar',
        signOut: 'Sair',
        country: 'País',
        city: 'Cidade',
        noOptions: 'Sem opções',
        males: 'Homens',
        females: 'Uma mulher',
        age: 'Era',
        from: 'A partir de',
        to: 'Para',
        of: 'Do',
        ageShort: 'anos',
        cantDetectFace: 'Por favor, selecione a foto com o rosto claramente visível',
        face: 'Enfrentar',
        noMatchesFound: 'Nenhuma equivalência encontrada',
        photoToRecognize: 'Foto para reconhecer',
        missingCityError: 'Por favor, selecione cidade',
        yes: 'sim',
        no: 'não',
        stopSearchConfirmation: 'Tem certeza de que quer terminar a pesquisa? Todos os resultados da pesquisa serão removidos',
        startSearch: 'Começe a pesquisar',
        supportUkraine: 'Apoie a Ucrânia',
        followUs: "Siga-nos",
        open: 'Abrir',
        copiedToClipboard: 'Copiado para a área de transferência',
        close: 'Perto',
        searchFinished: 'Pesquisa concluída',
        selferAdMessage: 'Tire uma foto com {0} aplicativo',
        ihancerAdMessage: 'Melhore a qualidade da foto com o aplicativo {0} para obter melhores resultados de pesquisa',
        privacyPolicy: 'Política de Privacidade',

        // Faces search component
        facesSearchComponentSubtitle: 'Pesquise as fotos do seu dispositivo por rosto',
        facesSearchComponentLoadingModels: 'Modelos de carregamento',
        facesSearchComponentFaceExamples: 'Exemplos de rosto',
        facesSearchComponentFaceExample: 'Exemplo de face',
        facesSearchComponentFacesToSearch: 'Faces para pesquisar',
        facesSearchComponentFaceToSearch: 'Face a pesquisa',
        facesSearchComponentTabVK: 'VKontakte',
        facesSearchComponentTabPhoto: 'Foto',
        facesSearchComponentHowItWorks: 'Como funciona',
        facesSearchComponentDescription: 'Você precisa encontrar todas as fotos no dispositivo com uma pessoa específica? Experimente este app, ele realiza busca de fotos do aparelho por rosto. Pode ser usado para encontrar pessoa por foto ou para procurar sósias. Você pode ver o processo de pesquisa em tempo real com a capacidade de interrompê-lo. A foto encontrada pode ser excluída, se necessário, ou compartilhada com outros aplicativos, como redes sociais, mensageiros, etc. \n\nFotos com rostos que você deseja pesquisar podem ser selecionadas na galeria ou tiradas da câmera. O aplicativo detecta automaticamente rostos, se mais de um for encontrado, você pode selecionar um específico para pesquisar. Para reconhecer rostos, a tecnologia de aprendizado de máquina é usada, portanto, se a foto contiver pelo menos um rosto semelhante ou igual, ele será encontrado.',

        // VK
        vkManagerAccessTokenNotFound: 'Token de acesso não encontrado',
        vkSearchComponentSearchParams: 'Opções de pesquisa',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'Pesquisar apenas faces muito semelhantes',
        vkSearchUsersNotFound: 'Usuários não encontrados',
        joinVKGroup: 'Junte-se ao nosso grupo VK para obter mais aplicativos gratuitos',

        // Device search
        deviceSearchSelectFolder: 'Selecione uma pasta com imagens para procurar pessoas',
        deviceSearchItemCopyName: 'Copiar nome',
        deviceSearchItemCopyLocation: 'Copiar local',
    },

    de: {
        // App head
        appTitle: 'Face Sherlock: suchen Sie ähnliche Personen mit dem Foto',
        appMetaDescription: 'Durchsuchen Sie Ihre Gerätefotos nach Gesicht',

        // Common
        unknownError: 'Unbekannter Fehler',
        signIn: 'Einloggen',
        signOut: 'Austragen',
        country: 'Land',
        city: 'Stadt',
        noOptions: 'Keine Optionen',
        males: 'Männlich',
        females: 'Weibchen',
        age: 'Alter',
        from: 'Von',
        to: 'Zu',
        of: 'Von',
        ageShort: 'jahre alt',
        cantDetectFace: 'Bitte wählen Sie ein Foto mit deutlich sichtbarem Gesicht aus',
        face: 'Gesicht',
        noMatchesFound: 'Keine Treffer gefunden',
        photoToRecognize: 'Foto zu erkennen.',
        missingCityError: 'Bitte wählen Sie Stadt',
        yes: 'jawohl',
        no: 'nein',
        stopSearchConfirmation: 'Möchten Sie sicher, dass Sie die Suche abschließen möchten? Alle Suchergebnisse werden entfernt',
        startSearch: 'Suche starten',
        supportUkraine: 'Unterstützen Sie die Ukraine',
        followUs: "Folge uns",
        open: 'Offen',
        copiedToClipboard: 'In die Zwischenablage kopiert',
        close: 'Nah dran',
        searchFinished: 'Suche beendet',
        selferAdMessage: 'Machen Sie ein Foto mit {0} App',
        ihancerAdMessage: 'Verbessern Sie die Fotoqualität mit der App {0}, um bessere Suchergebnisse zu erhalten',
        privacyPolicy: 'Datenschutz-Bestimmungen',

        // Faces search component
        facesSearchComponentSubtitle: 'Durchsuchen Sie Ihre Gerätefotos nach Gesicht',
        facesSearchComponentLoadingModels: 'Laden von Modellen',
        facesSearchComponentFaceExamples: 'Gesichtsbeispiele',
        facesSearchComponentFaceExample: 'Gesichtsbeispiel',
        facesSearchComponentFacesToSearch: 'Gesichter zu suchen',
        facesSearchComponentFaceToSearch: 'Angesicht zu Suche',
        facesSearchComponentTabVK: 'VKontakte',
        facesSearchComponentTabPhoto: 'Foto',
        facesSearchComponentHowItWorks: 'Wie es funktioniert',
        facesSearchComponentDescription: 'Müssen Sie alle Fotos auf dem Gerät mit einer bestimmten Person finden? Probieren Sie diese App aus, sie führt eine Suche nach Gerätefotos nach Gesicht durch. Kann verwendet werden, um Personen anhand von Fotos zu finden oder nach Doppelgängern zu suchen. Sie können den Suchvorgang in Echtzeit verfolgen und ihn stoppen. Gefundenes Foto kann bei Bedarf gelöscht oder mit anderen Apps wie sozialen Netzwerken, Messengern usw. geteilt werden. \n\nFotos mit Gesichtern, die Sie suchen möchten, können aus der Galerie ausgewählt oder von der Kamera aufgenommen werden. Die App erkennt dann automatisch Gesichter, wenn mehr als eines gefunden wird, können Sie ein bestimmtes zum Suchen auswählen. Um Gesichter zu erkennen, wird maschinelle Lerntechnologie verwendet. Wenn also ein Foto mindestens ein ähnliches oder gleiches Gesicht enthält, wird es gefunden.',

        // VK
        vkManagerAccessTokenNotFound: 'Zugangsstoken nicht gefunden',
        vkSearchComponentSearchParams: 'Suchoptionen',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'Nur sehr ähnliche Gesichter suchen',
        vkSearchUsersNotFound: 'Benutzer nicht gefunden',
        joinVKGroup: 'Treten Sie unserer VK-Gruppe bei, um mehr kostenlose Apps zu erhalten',

        // Device search
        deviceSearchSelectFolder: 'Bitte wählen Sie einen Ordner mit Bildern aus, um nach Personen zu suchen',
        deviceSearchItemCopyName: 'Namen kopieren',
        deviceSearchItemCopyLocation: 'Standort kopieren',
    },

    ar: {
        // App head
        appTitle: 'Face Sherlock: ابحث عن أشخاص مشابهين بالصور',
        appMetaDescription: 'ابحث عن صور الأشخاص على جهازك حسب الوجه',

        // Common
        unknownError: 'خطأ غير معروف',
        signIn: 'تسجيل الدخول',
        signOut: 'خروج',
        country: 'دولة',
        city: 'مدينة',
        noOptions: 'لا يوجد خيارات',
        males: 'ذكور',
        females: 'إناث',
        age: 'سن',
        from: 'من',
        to: 'إلى',
        of: 'ل',
        ageShort: 'ص / س',
        cantDetectFace: 'الرجاء تحديد صورة ذات وجه مرئي بوضوح',
        face: 'وجه',
        noMatchesFound: 'لم يتم العثور على تطابق',
        photoToRecognize: 'الصورة للتعرف عليها',
        missingCityError: 'من فضلك ، اختر المدينة',
        yes: 'نعم',
        no: 'رقم',
        stopSearchConfirmation: 'هل أنت متأكد أنك تريد إنهاء البحث؟ ستتم إزالة جميع نتائج البحث',
        startSearch: 'ابدأ البحث',
        supportUkraine: 'دعم أوكرانيا',
        followUs: "تابعنا",
        open: 'فتح',
        copiedToClipboard: 'نسخ إلى الحافظة',
        close: 'قريب',
        searchFinished: 'انتهى البحث',
        selferAdMessage: 'التقط صورة مع تطبيق {0}',
        ihancerAdMessage: 'حسِّن جودة الصور باستخدام تطبيق {0} للحصول على نتائج بحث أفضل',
        privacyPolicy: 'سياسة خاصة',

        // Faces search component
        facesSearchComponentSubtitle: 'ابحث عن صور الأشخاص على جهازك حسب الوجه',
        facesSearchComponentLoadingModels: 'نماذج التحميل',
        facesSearchComponentFaceExamples: 'أمثلة الوجه',
        facesSearchComponentFaceExample: 'مثال الوجه',
        facesSearchComponentFacesToSearch: 'وجوه للبحث',
        facesSearchComponentFaceToSearch: 'وجه للبحث',
        facesSearchComponentTabVK: 'VKontakte',
        facesSearchComponentTabPhoto: 'صورة',
        facesSearchComponentHowItWorks: 'كيف تعمل',
        facesSearchComponentDescription: 'هل تحتاج إلى العثور على جميع الصور الموجودة على الجهاز مع شخص معين؟ جرب هذا التطبيق ، فهو يقوم بالبحث عن صور الجهاز حسب الوجه. يمكن استخدامها للعثور على شخص عن طريق الصورة أو للبحث عن مشابهين. يمكنك مشاهدة عملية البحث في الوقت الحقيقي مع إمكانية إيقافها. يمكن حذف الصورة التي تم العثور عليها إذا لزم الأمر أو مشاركتها مع تطبيقات أخرى مثل الشبكات الاجتماعية والمراسلين وما إلى ذلك. \n\nيمكن تحديد الصور ذات الوجوه التي تريد البحث عنها من المعرض أو التقاطها من الكاميرا. يكتشف التطبيق بعد ذلك الوجوه تلقائيًا ، إذا تم العثور على أكثر من وجه ، يمكنك تحديد وجه محدد للبحث فيه. للتعرف على الوجوه ، يتم استخدام تقنية التعلم الآلي ، لذلك إذا كانت الصورة تحتوي على وجه واحد مشابه أو متماثل على الأقل ، فسيتم العثور عليها.',

        // VK
        vkManagerAccessTokenNotFound: 'لم يتم العثور على رمز الوصول',
        vkSearchComponentSearchParams: 'خيارات البحث',
        vkSearchComponentSearchOnlyVerySimilarFaces: 'ابحث فقط في الوجوه المتشابهة جدًا',
        vkSearchUsersNotFound: 'لم يتم العثور على المستخدمين',
        joinVKGroup: 'انضم إلى مجموعة VK للحصول على المزيد من التطبيقات المجانية',

        // Device search
        deviceSearchSelectFolder: 'الرجاء تحديد مجلد بالصور للبحث عن الأشخاص',
        deviceSearchItemCopyName: 'نسخ الاسم',
        deviceSearchItemCopyLocation: 'نسخ الموقع',
    },
});

export function getDeviceLanguage() {
    return localizedStrings.getInterfaceLanguage().split('-')[0]
}