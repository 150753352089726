import {ActionType} from "../actions/Types";

export let SearchStatus = {
    STOPPED: 'STOPPED',
    IN_PROGRESS: 'IN_PROGRESS',
    PAUSED: 'PAUSED',
    FINISHED: 'FINISHED'
};

let initialState = {
    searchStatus: SearchStatus.STOPPED,
    dataToProcess: [],
    processingItemIndex: 0,
    searchItems: [],
    selectedFolderName: null
};

let deviceSearch = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_DEVICE_SEARCH_STATUS:
            return {
                ...state,
                searchStatus: action.status
            };

        case ActionType.SET_DEVICE_SEARCH_PROCESSING_ITEM_INDEX:
            return {
                ...state,
                processingItemIndex: action.index
            };

        case ActionType.SET_DEVICE_SEARCH_DATA_TO_PROCESS:
            return {
                ...state,
                dataToProcess: action.data
            };

        case ActionType.ADD_DEVICE_SEARCH_ITEM:
            return {
                ...state,
                searchItems: [...state.searchItems, action.item]
            };

        case ActionType.SET_DEVICE_SEARCH_ITEM:
            let searchItems = state.searchItems.slice();
            searchItems[action.index] = action.item;
            return {
                ...state,
                searchItems: searchItems
            };

        case ActionType.SET_DEVICE_SEARCH_ITEMS:
            return {
                ...state,
                searchItems: action.items
            };

        case ActionType.SET_DEVICE_SEARCH_SELECTED_FOLDER_NAME:
            return {
                ...state,
                selectedFolderName: action.name
            };

        default: return state
    }
};

export default deviceSearch;