import { Component } from "react";
import connect from "react-redux/es/connect/connect";
import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import FolderIcon from '@material-ui/icons/Folder';
import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import BaseSnackBar from "./BaseSnackBar.js";
import './DeviceSearch.css';
import { localizedStrings } from "../utils/LocalizedStrings";
import { setDataToProcess, pauseSearch, startSearch, stopSearch } from "../actions/DeviceSearch";
import { SearchStatus } from "../reducers/DeviceSearch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import NavigationManager from "../utils/NavigationManager.js";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { theme } from './Root.js';

class DeviceSearch extends Component {
    constructor(props) {
        super(props);
        this.snackBar = React.createRef();
        this.imageInput = React.createRef();
        this.state = {
            isStopSearchConfirmationAlertOpen: false,
            searchItemMenuAnchor: null,
            searchItemForMenu: null
        }
    }

    render() {
        return (
            <div className='DeviceSearchRoot'>
                {this.input()}
                {this.searchButtons()}
                {this.searchItems()}
                <BaseSnackBar ref={this.snackBar} />
                {this.stopSearchDialog()}
                {this.searchItemMenu()}
            </div>
        );
    }

    input = () => {
        let selectedFolderName = this.props.selectedFolderName;
        return <div>
            <div>{localizedStrings.deviceSearchSelectFolder}</div>
            <div className='DeviceSearchInputButton'>
                <IconButton
                    color='primary'
                    onClick={this.chooseFolderClicked}>
                    <FolderIcon style={{ fontSize: '80px' }} />
                </IconButton>
            </div>
            {
                selectedFolderName
                    ? <div className='DeviceSearchFolderName' style={{ color: theme.palette.primary.main }}>
                        <b>{selectedFolderName}</b>
                    </div>
                    : null
            }
            <input type='file'
                webkitdirectory=''
                multiple=''
                ref={this.imageInput}
                onChange={this.loadInputFiles}
                style={{ display: 'none' }} />
        </div>
    }

    chooseFolderClicked = () => {
        if (this.props.searchStatus === SearchStatus.STOPPED) {
            this.imageInput.current.click();
        } else {
            this.setState({
                isStopSearchConfirmationAlertOpen: true
            })
        }
    }

    loadInputFiles = (event) => {
        this.props.setDataToProcess(event.target.files);
        this.startSearch();
    };

    searchButtons() {
        if (this.props.dataToProcess.length === 0) return;
        let searchStatus = this.props.searchStatus;
        let startSearchGridItem;

        switch (searchStatus) {
            case SearchStatus.STOPPED:
                startSearchGridItem = <Button
                    variant='contained'
                    color='primary'
                    onClick={this.startSearch}
                >
                    {localizedStrings.startSearch}
                </Button>;
                break;
            case SearchStatus.IN_PROGRESS:
            case SearchStatus.PAUSED:
                startSearchGridItem = <Grid item>
                    <IconButton
                        color='primary'
                        className='IconButton'
                        onClick={() => {
                            searchStatus === SearchStatus.IN_PROGRESS
                                ? this.props.pauseSearch()
                                : this.startSearch()
                        }}>
                        {
                            searchStatus === SearchStatus.IN_PROGRESS
                                ? <PauseIcon />
                                : <PlayArrowIcon />
                        }
                    </IconButton>
                </Grid>
                break;
        }

        let stopSearchGridItem;
        if (this.props.isSearchNotStopped)
            stopSearchGridItem = <Grid item>
                <IconButton
                    color='primary'
                    className='IconButton'
                    onClick={() => {
                        this.setState({
                            isStopSearchConfirmationAlertOpen: true
                        })
                    }}>
                    <StopIcon />
                </IconButton>
            </Grid>;

        return <div className='DeviceSearchButtons'>
            <Grid container justify="center"
                spacing={8}>
                {startSearchGridItem}
                {stopSearchGridItem}
            </Grid>
        </div>;
    }

    startSearch = async () => {
        try {
            await this.props.startSearch();
            this.snackBar.current.showMessage(localizedStrings.searchFinished);
        } catch (error) {
            this.snackBar.current.handleError(error);
        }
    }

    closeStopConfirmationAlert = () => {
        this.setState({
            isStopSearchConfirmationAlertOpen: false
        })
    };

    searchItems() {
        return <Grid container justify='center'
            spacing={16}>
            {this.props.searchItems.map((item) => {
                let progressView;
                if (item.inProgress) {
                    progressView = <div className='DeviceSearchItemProgressView'>
                        <span><b>{this.props.processingItemIndex + 1}</b></span>
                    </div>;
                }

                return <Paper className='DeviceSearchItem'>
                    <ButtonBase
                        disabled={item.inProgress}
                        onClick={(event) => this.searchItemClicked(event, item.data)}>
                        <div className='DeviceSearchItemDiv'>
                            <img src={item.imageSrc}
                                className={item.inProgress ? 'DeviceSearchItemImage InProgressOpacity' : 'DeviceSearchItemImage'}
                                alt={item.inProgress ? localizedStrings.photoToRecognize : item.data.name} />
                            {!item.inProgress ? <span className='DeviceSearchItemTitle'>{item.data.name}</span> : null}
                            {progressView}
                        </div>
                    </ButtonBase>
                </Paper>
            })}
        </Grid>
    }

    searchItemClicked(event, file) {
        this.setState({ 
            searchItemMenuAnchor: event.currentTarget.firstChild,
            searchItemForMenu: file
        });
    }

    stopSearchDialog() {
        return <Dialog
            open={this.state.isStopSearchConfirmationAlertOpen}
            onClose={this.closeStopConfirmationAlert}
            aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">{localizedStrings.stopSearchConfirmation}</DialogTitle>
            <DialogActions>
                <Button onClick={this.closeStopConfirmationAlert} color="primary">
                    {localizedStrings.no}
                </Button>
                <Button onClick={() => {
                    this.closeStopConfirmationAlert();
                    this.props.stopSearch();
                }} color="primary" autoFocus>
                    {localizedStrings.yes}
                </Button>
            </DialogActions>
        </Dialog>
    }

    searchItemMenu() {
        let searchItemMenuAnchor = this.state.searchItemMenuAnchor;
        return <Menu
            anchorEl={searchItemMenuAnchor}
            open={Boolean(searchItemMenuAnchor)}
            onClose={this.closeFaceToSearchMenu}>
            <MenuItem onClick={() => {
                NavigationManager.openImageFile(this.state.searchItemForMenu)
                this.closeFaceToSearchMenu();
            }}>{localizedStrings.open}</MenuItem>
            <MenuItem onClick={() => {
                NavigationManager.copyToClipboard(this.state.searchItemForMenu.name);
                this.snackBar.current.showMessage(localizedStrings.copiedToClipboard);
                this.closeFaceToSearchMenu();
            }}>{localizedStrings.deviceSearchItemCopyName}</MenuItem>
            <MenuItem onClick={() => {
                NavigationManager.copyToClipboard(this.state.searchItemForMenu.webkitRelativePath);
                this.snackBar.current.showMessage(localizedStrings.copiedToClipboard);
                this.closeFaceToSearchMenu();
            }}>{localizedStrings.deviceSearchItemCopyLocation}</MenuItem>
        </Menu>
    }

    closeFaceToSearchMenu = () => {
        this.setState({ 
            searchItemMenuAnchor: null, 
            searchItemForMenu: null 
        });
    };
}

let mapStateToProps = state => ({
    processingItemIndex: state.deviceSearch.processingItemIndex,
    searchItems: state.deviceSearch.searchItems,
    searchStatus: state.deviceSearch.searchStatus,
    isSearchNotStopped: state.deviceSearch.searchStatus !== SearchStatus.STOPPED,
    dataToProcess: state.deviceSearch.dataToProcess,
    selectedFolderName: state.deviceSearch.selectedFolderName,
});

function mapDispatchToProps(dispatch) {
    return {
        setDataToProcess: (data) => dispatch(setDataToProcess(data)),
        startSearch: () => dispatch(startSearch()),
        pauseSearch: () => dispatch(pauseSearch()),
        stopSearch: () => dispatch(stopSearch())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceSearch);