import React, { Component } from 'react';
import './FacesSearch.css';
import { connect } from 'react-redux'
import { configureFaceRecognition, deleteFaceToSearchData, setFaceToSearch, openImageInApp } from "../actions/FacesSearch";
import { localizedStrings } from "../utils/LocalizedStrings";
import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase";
import Grid from "@material-ui/core/Grid/Grid";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import BaseSnackBar from "./BaseSnackBar";
import { Helmet } from "react-helmet";
import { SearchStatus } from "../reducers/VKSearch";
import AnalyticsService, { Pages } from "../utils/AnalyticsService";
import NavigationManager from "../utils/NavigationManager";
import { Constants as NavigationManagerConstants } from "../utils/NavigationManager";
import Ad from "./Ad";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import StoresButtons from "./StoresButton/StoresButtons";
import ChooseImageIcon from './ChooseImageIcon';
import ProgressButton from './ProgressButton';
import DeviceSearch from './DeviceSearch';

let Constants = {
    faceToSearchImageId: 'faceToSearchImage'
};

let SearchTabType = {
    vk: 0,
    onPhoto: 1
};

let windowResizeEvent = 'resize';

class FacesSearch extends Component {

    clickedFaceToSearchImage: HTMLImageElement;
    
    constructor(props) {
        super(props);
        this.snackBar = React.createRef();
        this.imageInput = React.createRef();
        this.faceExamplesView = React.createRef();
    }

    state = {
        faceToSearchMenuAnchor: null,
        searchTypeTabIndex: SearchTabType.vk,
        appDescriptionExpanded: false
    };

    componentDidMount() {
        window.addEventListener(windowResizeEvent, this.windowResized.bind(this));
        AnalyticsService.logPageView(Pages.FACES_SEARCH);
        this.checkQueryImageUrl();
    }

    checkQueryImageUrl() {
        let query = new URLSearchParams(this.props.location.search);
        let imageUrl = query.get('image_url');
        if (imageUrl) this.setExampleFaceToSearch(imageUrl);
    }

    componentWillUnmount() {
        window.removeEventListener(windowResizeEvent, this.windowResized.bind(this));
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        this.alignFaceExamples();
    }

    windowResized() {
        this.alignFaceExamples();
    }

    alignFaceExamples() {
        let faceExamplesView = this.faceExamplesView.current;
        if (!faceExamplesView) return;
        let isFaceExamplesViewScrollable = faceExamplesView.scrollWidth > faceExamplesView.clientWidth;
        faceExamplesView.style.justifyContent = isFaceExamplesViewScrollable ? 'left' : 'center';
    }

    setExampleFaceToSearch(imageSrc: string) {
        for (let i = 0; i < this.props.facesToSearch.length; i++) {
            let imageView = document.getElementById(Constants.faceToSearchImageId + i);
            if (!this.getFaceToSearchStoreSrc(imageView)) {
                imageView.src = imageSrc;
                this.setFaceToSearch(i, imageView, imageSrc);
                break;
            }
        }
    }

    setFaceToSearch(index: number, imageView: HTMLImageElement, imageSrc: string) {
        this.props.setFaceToSearch(index, imageView, imageSrc)
            .catch(this.snackBar.current.handleError);
    }

    faceToSearchClicked = (event) => {
        let img = event.currentTarget.firstChild;
        this.clickedFaceToSearchImage = img;
        this.openImagePicker();
    };

    closeFaceToSearchMenu = () => {
        this.setState({ faceToSearchMenuAnchor: null });
    };

    deleteFaceToSearchData = (index) => {
        this.props.deleteFaceToSearchData(index);
    };

    loadInputImage = (event) => {
        let reader = new FileReader();
        reader.onload = () => {
            let imageSrc = reader.result;
            this.clickedFaceToSearchImage.src = imageSrc;
            let faceIndex = this.getFaceToSearchIndex(this.clickedFaceToSearchImage);
            this.setFaceToSearch(faceIndex, this.clickedFaceToSearchImage, imageSrc);
            this.imageInput.current.value = '';
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    getFaceToSearchIndex(faceToSearchImage) {
        if (!faceToSearchImage) return;
        return parseInt(faceToSearchImage.id.replace(Constants.faceToSearchImageId, ''));
    }

    getFaceToSearchStoreSrc(faceToSearchImage: HTMLImageElement) {
        let index = this.getFaceToSearchIndex(faceToSearchImage);
        if (index == null) return;
        return this.props.facesToSearch[index].imageSrc
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            searchTypeTabIndex: newValue
        })
    };

    openImagePicker() {
        this.imageInput.current.click();
    }

    socialLinksView() {
        return <div>
            {localizedStrings.followUs}:
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonBase
                    disableRipple
                    className="SocialButton"
                    onClick={() => NavigationManager.openUrlInNewTab(NavigationManagerConstants.APPSMOTOR_INSTAGRAM_URL)}>
                    <img height={40}
                        className={'FaceImageScaleDownFit'}
                        src={'/images/Instagram_Glyph_Gradient.png'}
                        alt={localizedStrings.followUs}
                    />
                </ButtonBase>
            </div>
        </div>
    }

    privacyPolicyLink() {
        return <div className="PrivacyPolicyLink">
            <a target="_blank" rel="noopener noreferrer" href={NavigationManager.privacyPolicyUrl}>
                {localizedStrings.privacyPolicy}
            </a>
        </div>;
    }

    render() {
        let faceToSearchMenuAnchor = this.state.faceToSearchMenuAnchor;
        let clickedFaceToSearchImage = this.clickedFaceToSearchImage;
        let deleteImageMenuItem;
        if (clickedFaceToSearchImage && this.getFaceToSearchStoreSrc(clickedFaceToSearchImage)) {
            let faceIndex = this.getFaceToSearchIndex(clickedFaceToSearchImage);
            deleteImageMenuItem = <MenuItem onClick={() => {
                this.deleteFaceToSearchData(faceIndex);
                this.closeFaceToSearchMenu();
            }}>Delete image</MenuItem>
        }

        return (
            <div className="Root">
                <Helmet
                    title={localizedStrings.appTitle}
                    meta={[
                        {
                            name: 'description',
                            content: localizedStrings.appMetaDescription
                        }
                    ]}
                />
                <h1>FACE SHERLOCK</h1>
                <h3>{localizedStrings.facesSearchComponentSubtitle}</h3>
                {this.warInfo()}
                {this.facesSelectionView()}
                {this.ihancerAd()}
                {this.deviceSearch()}
                {this.mobileSearchButton()}
                {this.adBanner()}
                {this.appDescription()}
                <StoresButtons />
                {this.socialLinksView()}
                {this.privacyPolicyLink()}
                <input type='file'
                    id="imageInput"
                    ref={this.imageInput}
                    accept="image/*"
                    onChange={this.loadInputImage}
                    style={{ display: 'none' }} />
                <Menu
                    id="simple-menu"
                    anchorEl={faceToSearchMenuAnchor}
                    open={Boolean(faceToSearchMenuAnchor)}
                    onClose={this.closeFaceToSearchMenu}
                >
                    <MenuItem onClick={() => {
                        this.openImagePicker();
                        this.closeFaceToSearchMenu();
                    }}>Choose image</MenuItem>
                    {deleteImageMenuItem}
                </Menu>
                <BaseSnackBar ref={this.snackBar} />
            </div>
        );
    }

    warInfo() {
        return <a className="WarInfo"
            style={{ backgroundImage: 'url(images/ua_flag.jpg)' }}
            href={NavigationManager.getSupportUkraineUrl()}
            target="_blank"
            rel="noopener noreferrer">
            <span>{localizedStrings.supportUkraine}</span>
        </a>
    }

    facesSelectionView() {
        let isSearchNotStopped = this.props.isSearchNotStopped;
        let searchNotStoppedClassName = isSearchNotStopped ? ' Disabled' : '';
        let faceToSearchIndex = 0;
        let facesToSearch = this.props.facesToSearch.map((face) => {
            let setFaceInProgress = face.inProgress;
            let imageSrc = face.imageSrc;
            let view = <Grid item>
                <div className={'FaceToSearchImageContainer' + searchNotStoppedClassName}>
                    {setFaceInProgress
                        ? <CircularProgress disableShrink='true' />
                        : <ButtonBase
                            disabled={isSearchNotStopped || setFaceInProgress}
                            onClick={this.faceToSearchClicked}
                            className='FaceImageButton'
                            style={{ borderRadius: '8px' }}>
                            <img id={Constants.faceToSearchImageId + faceToSearchIndex}
                                className={imageSrc ? 'FaceImage' : 'Hidden'}
                                src={imageSrc}
                                alt={localizedStrings.facesSearchComponentFaceToSearch} />
                            {!imageSrc && <ChooseImageIcon />}
                        </ButtonBase>
                    }
                    {this.selferAd()}
                </div>
            </Grid>;
            faceToSearchIndex++;
            return view;
        });

        let facesSelectionView = <div>
            <Grid container
                justify="center"
                style={{ width: '100%', padding: 8 }}
            >
                {facesToSearch}
            </Grid>
        </div>;

        return facesSelectionView;
    }

    selferAd() {
        return <a target="_blank" rel="noopener noreferrer" href="https://selfercamera.com">
            <div className='SelferAd'>
                {localizedStrings.formatString(localizedStrings.selferAdMessage, <b>selfer</b>)}
            </div>
        </a>
    }

    mobileSearchButton() {
        let selectedImages = this.props.facesToSearch.filter(face => face.imageSrc)
        return !this.props.isDeviceSearchSupported && selectedImages.length > 0
            ? <ProgressButton
                title='Search'
                inProgress={this.props.isImageUploading}
                onClick={() =>
                    this.props
                        .openImageInApp(selectedImages[0].imageSrc)
                        .catch(this.snackBar.current.handleError)
                } />
            : null
    }

    ihancerAd() {
        return <div className="IhancerAd">
            <a target="_blank" rel="noopener noreferrer" href="https://ihancer.com">
            {localizedStrings.formatString(localizedStrings.ihancerAdMessage, <span><b>ihancer</b></span>)}
            </a>
        </div>;
    }

    deviceSearch() {
        let selectedImages = this.props.facesToSearch.filter(face => face.imageSrc)
        return this.props.isDeviceSearchSupported && selectedImages.length > 0 ? <DeviceSearch /> : null;
    }

    adBanner() {
        return <Ad
            slot='6732813240'
            margin='24px'
        />
    }

    appDescription() {
        if (this.props.modelsLoadingInProgress) return;
        return <div className='AppDescription'>
            <ExpansionPanel expanded={this.state.appDescriptionExpanded}
                onChange={() => this.appDescriptionTapped()}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><b>{localizedStrings.facesSearchComponentHowItWorks}</b></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        {localizedStrings.facesSearchComponentDescription}
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    }

    appDescriptionTapped() {
        let value = !this.state.appDescriptionExpanded
        this.setState({ appDescriptionExpanded: value });
    }
}

let mapStateToProps = state => ({
    facesExamples: state.facesSearch.facesExamples,
    facesToSearch: state.facesSearch.facesToSearch,
    modelsLoadingInProgress: state.facesSearch.modelsLoadingInProgress,
    searchItems: state.vkSearch.searchItems,
    isSearchNotStopped: state.deviceSearch.searchStatus !== SearchStatus.STOPPED,
    searchOnPhotoMatchesCount: state.searchOnPhoto.matchedFacesCount,
    facesToSearchWithFaceDescriptors: state.facesSearch.facesToSearch.filter(faceToSearch => faceToSearch.faceDescriptor != null),
    isImageUploading: state.facesSearch.isImageUploading,
    isDeviceSearchSupported: state.facesSearch.isDeviceSearchSupported
});

function mapDispatchToProps(dispatch) {
    return {
        configureFaceRecognition: () => dispatch(configureFaceRecognition()),
        setFaceToSearch: (faceIndex: number,
            imageView: HTMLImageElement,
            imageSrc: string) =>
            dispatch(setFaceToSearch(faceIndex, imageView, imageSrc)),
        deleteFaceToSearchData: (faceIndex: number) => dispatch(deleteFaceToSearchData(faceIndex)),
        openImageInApp: (file) => dispatch(openImageInApp(file))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FacesSearch);
