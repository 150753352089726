import React from 'react';
import './StoresButtons.css';

export const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.appsmotor.facechecker";
export const APP_STORE_LINK = "https://apps.apple.com/app/face-sherlock-search-by-face/id1603900106";

export default class StoresButtons extends React.Component {
    render() {
        return <div className='StoresButtonsContainer'>
            <a target="_blank" rel="noopener noreferrer" href={GOOGLE_PLAY_LINK}>
                <img height="45px" src="images/google-play-badge.png" alt="Download on Google Play" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={APP_STORE_LINK}>
                <img height="45px" src="images/app-store-badge.png" alt="Download on App Store" />
            </a>
        </div>
    }
}